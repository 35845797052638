<template>

  <b-card title="Universal Payment Master">
    <!-- modal -->
    <b-modal
      id="modal-prevent-closing"
      ref="my-modal"
      title="Mark as Paid"
      ok-title="Submit"
      ok-only
      @ok="handleOk"
      :hide-footer="alertMessage == 1"
    >
        <span v-if="alertMessage == 0">
            <validation-observer ref="simpleRules">
                <form
                    ref="form"
                    @submit.stop.prevent="handleSubmit"
                >
                    <b-form-group
                    :state="transactionIdState"
                    label="Transaction Ref ID"
                    label-for="transaction-id"
                    invalid-feedback="Transaction Ref ID is required"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="Transaction Ref ID"
                            rules="required"
                        >
                            <b-form-input
                                id="transaction-id"
                                v-model="transaction_id"
                                :state="errors.length > 0 ? false:null"
                                required
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                    <b-form-group
                    :state="dateState"
                    label="Payment Date"
                    label-for="payment-date"
                    invalid-feedback="Date is required"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="Payment Date"
                            rules="required"
                        >
                            <b-form-datepicker
                                id="payment-date"
                                v-model="newdate"
                                :state="errors.length > 0 ? false:null"
                                required
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>
                </form>
            </validation-observer>
        </span>
        <span v-else-if="alertMessage == 1">
            <span v-if="returnMessageStatus == 0">
                <b-alert
                variant="success"
                show
                >
                <div class="alert-body">
                    <span>{{returnMessage}}</span>
                </div>
                </b-alert>
            </span>
            <span v-else-if="returnMessageStatus == 1">
                <b-alert
                variant="danger"
                show
                >
                <div class="alert-body">
                    <span>{{returnMessage}}</span>
                </div>
                </b-alert>
            </span>
        </span>
    </b-modal>
    <b-tabs v-model="tabIndex" pills card>
      <b-tab 
      ref="tab1"
      active
      lazy
      title="Pending Seller Settlement">
      
            <!-- table -->
        <vue-good-table
          ref="universal-payment-master-courier"
          mode="remote"
          :columns="columns"
          :rows="rows"
          :isLoading="isLoadingProp" 
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :totalRows="totalRecords"
          @on-column-filter="onColumnFilter"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
        <div slot="loadingContent">
          <b-spinner
            ref="spinner_one"
            variant="primary"
            class="mr-1"
            type="grow"
            style="width: 4rem; height: 4rem;"
          />
        </div>
        <div slot="table-actions">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="greet(allData)"
            >
            Download CSV
        </b-button>
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.modal-prevent-closing
            variant="primary"
            class="mr-1"
            v-on:click="mark_as_paid"
            >
            Mark as Paid
        </b-button>
        <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="reconciled"
            >
            Mark as Reconciled
        </b-button> -->
      </div>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <!-- <span
              v-if="props.column.field === 'fullNamae'"
              class="text-nowrap"
            >
              <b-avatar
                :src="props.row.avatar"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span> -->

            <!-- Column: Status -->
            <span v-if="props.column.field === 'shipment_status'">
              <b-badge :variant="statusVariant(props.row.shipment_status)">
                {{ props.row.shipment_status }}
              </b-badge>
            </span>
            
            <span v-else-if="props.column.field == 'payment_settled'">
              <span v-if="props.row.payment_settled == 'Yes'">
                <b-badge variant="light-success">
                  {{ props.row.payment_settled }}
                </b-badge>
              </span>
              <span v-else>
                <b-badge variant="light-danger">
                  {{ props.row.payment_settled }}
                </b-badge>
              </span>
            </span>
            <!-- Column: Action -->
            <!-- <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item>
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span> -->

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">

              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-tab>
      <b-tab
        title="Shipments with Adjustments"
        lazy
      >

     
        <!-- table -->
        <vue-good-table
          ref="universal-payment-master-courier"
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :isLoading="isLoadingProp" 
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :totalRows="totalRecords"
          @on-column-filter="onColumnFilter"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
        <div slot="loadingContent">
          <b-spinner
            ref="spinner_one"
            variant="primary"
            class="mr-1"
            type="grow"
            style="width: 4rem; height: 4rem;"
          />
        </div>
        <div slot="table-actions">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="greet"
            >
            Download CSV
        </b-button>

        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-modal.modal-prevent-closing
            variant="primary"
            class="mr-1"
            v-on:click="mark_as_paid"
            >
            Mark as Paid
        </b-button>
       
      </div>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <b-avatar
                :src="props.row.avatar"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <span v-else-if="props.column.field == 'payment_settled'">
              <span v-if="props.row.payment_settled == 'Yes'">
                <b-badge variant="light-success">
                  {{ props.row.payment_settled }}
                </b-badge>
              </span>
              <span v-else>
                <b-badge variant="light-danger">
                  {{ props.row.payment_settled }}
                </b-badge>
              </span>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item>
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">

              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-tab>
      <b-tab
        title="Payments Settled to Seller"
        lazy
      >
        <!-- table -->
        <vue-good-table
          ref="universal-payment-master-reconciliation"
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :isLoading="isLoadingProp" 
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true,
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: true,
            selectAllByGroup: true,
          }"
          :totalRows="totalRecords"
          @on-column-filter="onColumnFilter"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
        <div slot="loadingContent">
          <b-spinner
            ref="spinner_one"
            variant="primary"
            class="mr-1"
            type="grow"
            style="width: 4rem; height: 4rem;"
          />
        </div>
        <div slot="table-actions">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="greet"
            >
            Download CSV
        </b-button>
        
      </div>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <span
              v-if="props.column.field === 'fullName'"
              class="text-nowrap"
            >
              <b-avatar
                :src="props.row.avatar"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span>

            <!-- Column: Status -->
            <span v-else-if="props.column.field === 'status'">
              <b-badge :variant="statusVariant(props.row.status)">
                {{ props.row.status }}
              </b-badge>
            </span>

            <span v-else-if="props.column.field == 'payment_settled'">
              <span v-if="props.row.payment_settled == 'Yes'">
                <b-badge variant="light-success">
                  {{ props.row.payment_settled }}
                </b-badge>
              </span>
              <span v-else>
                <b-badge variant="light-danger">
                  {{ props.row.payment_settled }}
                </b-badge>
              </span>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <span>
                <b-dropdown
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="text-body align-middle mr-25"
                    />
                  </template>
                  <b-dropdown-item>
                    <feather-icon
                      icon="Edit2Icon"
                      class="mr-50"
                    />
                    <span>Edit</span>
                  </b-dropdown-item>
                  <b-dropdown-item>
                    <feather-icon
                      icon="TrashIcon"
                      class="mr-50"
                    />
                    <span>Delete</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">

              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-tab>
    </b-tabs>

    

    

    
    
  </b-card>
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script type="text/javascript" src="libs/FileSaver/FileSaver.min.js"></script>
<script type="text/javascript" src="libs/js-xlsx/xlsx.core.min.js"></script>
<script type="text/javascript" src="tableExport.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/moment.js/2.18.1/moment.min.js"></script>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown,BCard,BButton,BTabs,BTab,BFormDatepicker,BAlert,BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import pages from '@/router/routes/pages'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    VueGoodTable,
    BFormDatepicker,
    BAlert,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BTab,
    BTabs,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BButton,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabIndex: 0,
      allData:null,
      log: [],
      required,
      pageLength: 10,
      totalRecords:0,
      get_main_data_url:'get-seller-payment-master-all',
      dir: false,
      transactionIdState: null,
      alertMessage:0,
      returnMessageStatus:0,
      returnMessage:null,
      isLoadingProp:true,
      dateState:null,
      transaction_id: '',
      newdate: new Date(),
      submittedNames: [],
      pages: ['10','20','50','100'],
      columns: [
        {
          label: 'Seller ID',
          field: 'seller_id',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Seller Name',
          field: 'seller_name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Order ID',
          field: 'marketplace_reference_id',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'CN #',
          field: 'tracking_number',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Courier',
          field: 'courier_name',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Courier Service',
          field: 'courier_service',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Status',
          field: 'shipment_status',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Shipment Booking Date',
          field: 'booking_date',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Terminal Date',
          field: 'terminal_date',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'COD Amount',
          field: 'cod',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Unity Weight',
          field: 'unity_weight',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Actual Weight',
          field: 'actual_weight',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Adjustment',
          field: 'seller_adjustment_amount',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
         {
          label: 'Net Payment',
          field: 'net_payment',
          formatFn:this.formatCharges,
          
        },
        {
          label: 'Payment Aging',
          field: 'payment_aging',
        },
        {
          label: 'Payments Settled to Seller',
          field: 'payment_settled',
        },
        {
          label: 'Payment Settled Date',
          field: 'payment_settled_date',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
      ],
      rows: [],
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '', // 'asc' or 'desc'
        },
        page: 1, // what page I want to show
        perPage: 10, // how many items I'm showing per page
      },
      searchTerm: '',
      shipment_status: [{
        1: 'Lost', 2: 'Delivered', 3: 'Return', 4: 'Losst', 5: 'Lost',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Lost      : 'light-primary',
        Delivered : 'light-success',
        Return     : 'light-danger',
        Losts    : 'light-warning',
        Losst      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return shipment_status => statusColor[shipment_status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {

    
      var d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      this.newdate = [year, month, day].join('-');
    

    if(this.tabIndex == 0){
      this.$http.post(this.get_main_data_url,{
        data:this.serverParams,
        topic:'pending_settlements'
        })
        .then(res => { this.rows = res.data.data;
          this.totalRecords = res.data.totalRecords;
          this.updateAllData(res.data.data);
          this.isLoadingProp = false;
        })
    } else if(this.tabIndex == 1){
      this.$http.post(this.get_main_data_url,{
        data:this.serverParams,
        topic:'shipments_with_adjustments'
        })
      .then(res => { this.rows = res.data;
        this.totalRecords = res.data.totalRecords;
        this.updateAllData(res.data.data);
        this.isLoadingProp = false;
      })
    } else{
      this.$http.post(this.get_main_data_url,{
        data:this.serverParams,
        topic:'payment_settled'
        })
      .then(res => { this.rows = res.data;
        this.totalRecords = res.data.totalRecords;
        this.updateAllData(res.data.data);
        this.isLoadingProp = false;
      })
    }
  },
  
  methods: {
    formatCharges:function(data){
      if(data != null){
        return data.toFixed(2);
      }
    },
    updateAllData(data){
      this.allData = null;
      this.allData = data;
    },
    updateParams(newProps) {
        
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    // handleSearch(searching) {
    //   this.log.push(`The user searched for: ${searching}`)
    // },
    handleChangePage(page) {
      this.updateParams({page: page});
      // this.log.push(`The user changed the page to: ${page}`)
      this.loadItems();
    },
    handlePageChange(active) {
      this.updateParams({perPage: active});
      this.log.push(`the user change page:  ${active}`)
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
      this.log.push(`the user ordered:  ${params[0].type}`)
    },
    onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
    },
    
    loadItems() {
        this.isLoadingProp = true;
        var topic = '';
      if(this.tabIndex == 0){
        topic = 'pending_settlements';
      } else if(this.tabIndex == 1){
        topic = 'shipments_with_adjustments';
      } else{
        topic = 'payment_settled';
      }
        this.$http.post(this.get_main_data_url,{
            data:this.serverParams,
            topic:topic
        }).then(response => {
            //  this.totalRecords = response.totalRecords;
            this.totalRecords = response.data.totalRecords;
            this.rows = response.data.data;
            this.updateAllData(response.data.data);
            this.isLoadingProp = false;
        });
    },
    mark_as_paid: function (event) {
    //   if((this.$refs['universal-payment-master-courier'].selectedRows).length > 0){
    //     this.$http.post('paid-by-courier-payment-master',this.$refs['universal-payment-master-courier'].selectedRows).then(response => {
    //       console.log(response)
    //       this.loadItems();
    //     });
    //   }
        this.alertMessage = 0;
        this.returnMessageStatus = 0;
        this.returnMessage = null;

        var d = new Date(),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;

      this.newdate = [year, month, day].join('-');
        this.transaction_id = null;
      
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {

        this.$refs.simpleRules.validate().then(success => {
            if(success){
              // console.log(this.newdate)
                if((this.$refs['universal-payment-master-courier'].selectedRows).length > 0){
                    this.$http.post('seller-payment-marked-paid',{
                        shipments : this.$refs['universal-payment-master-courier'].selectedRows,
                        transaction_id:this.transaction_id,
                        date:this.newdate
                    }).then(response => {
                        this.alertMessage = 1;
                        this.returnMessageStatus = response.data.error;
                        this.returnMessage = response.data.message;
                        if(response.data.url != null){
                          window.open(response.data.url);
                        }
                        
                    this.loadItems();
                    });
                } else{
                  this.alertMessage = 1;
                  this.returnMessageStatus = 1;
                  this.returnMessage = "Please select a row";
                }
            }
        })
     
      
      // Hide the modal manually
    //   this.$nextTick(() => {
    //     this.$refs['my-modal'].toggle('#toggle-btn')
    //   })
    },

    greet: function (arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
      // $('.vgt-table bordered').tableExport({type:'csv'});
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>